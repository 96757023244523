import React from 'react';
import './HeroHome.css';

import mainLogo from './main_logo.png';


function HeroHome() {
  return (
    <div className="hero-home">
      <div className="hero-home-content">
        <div className="title">
            
          <h1>VULISCORE</h1>
          <h2>MEDICAL DISTRIBUTION SPECIALISTS</h2>
          <img src={mainLogo} alt='Main Logo' className='main_logo'/>
        </div>
        <div className="company-profile">
          <p>
            Vuliscore(PTY)Ltd. was established in 2015, and today is one of South Africa's leading manufacturer, importer and distributor of medical and surgical disposable products to the private and provincial healthcare industry within South Africa.
          </p>
          <br></br>
          <p>
            The company was solely formed by a previously disadvantaged South African, Indian female who is a qualified marketing professional focusing on the needs of the South African healthcare industry. Vuliscore has been awarded numerous government tenders in South Africa and has recently been successful in other African countries.
          </p>
          <br></br>
          <p>
            The company enjoys a high Brand Equity and all of its products are well accepted throughout the country in all major hospitals and clinics. Since its inception the company has grown organically and surged ahead providing a comprehensive range of products.
          </p>
          <br></br>
          <p>
            Vuliscore boasts a 1200 square metre factory in Wadeville, Johannesburg and is currently in search of vacant industrial land to build a customised medical manufacturing facility with in-house sterilisation facilities. Continuous market research, implementation of the latest technology developments at all levels, close liaising with customers ensuring customer satisfaction and high quality standards constitute key factors in the company's growth.
          </p>
          <br></br>
          <p>
            Vuliscore regards its employees as the nucleus and heartbeat of the organisation and fosters principles of leadership, empowerment, individual accountability and teamwork. The staff at Vuliscore work very closely with our customers to create customer-focused products, hence reducing product costs and increasing clinical efficiencies. Our goals are to provide innovative marketing platforms, exceptional customer satisfaction, inspired, motivated and empowered employees striving for superior financial results.
          </p>
        </div>
      </div>
    </div>
  );
}

export default HeroHome;
