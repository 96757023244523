import missionImage from "./mission.jpg";
import visionImage from "./vission.jpg";
import coreImage from "./corevalues.jpg";

export const informationData = [
  {
    id: 1,
    title: "MISSION",
    description:
      "Constantly endeavouring to provide affordable healthcare with superior clinical outcomes to all patients.",
    image: missionImage,
  },
  {
    id: 2,
    title: "VISSION",
    description:
      "To become a leading BEE supplier to the healthcare industry in South Africa and ultimately in Africa.",
    image: visionImage,
  },
  {
    id: 3,
    title: "CORE VALUES",
    description: `•Dedication to every patient's health.`,
    description2:
      "•Commitment to provide superior healthcare to the respective communities.",
    description3: "•Supporting employees excellence and happiness.",
    description4: "•Creating wealth ethically through profits and growth.",
    image: coreImage,
  },
];
