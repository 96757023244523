import React, { useState, useEffect } from 'react';
import './Navbar.css';
import { GiStethoscope } from 'react-icons/gi';

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <nav className="navigation">
      <div className="brand-container">
        <a href="/" className="brand-name">
          VULISCORE
        </a>
        <GiStethoscope className="logo" />
      </div>
      <button className={`hamburger \${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </button>
      {(isMobile ? menuOpen : true) && (
        <div className="navigation-menu">
          <ul>
            <li>
              <a href="/">HOME</a>
            </li>
            <li>
              <a href="/services">SERVICES</a>
            </li>
            <li>
              <a href="/contact">CONTACT US</a>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
