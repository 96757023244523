import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SubmissionCard.css';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from './Spinner';
import SubmissionConfirmation from './SubmissionConfirmation';

function SubmissionCard() {
  const form = useRef();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm('service_4s9h7vo', 'template_7s3hcij', form.current, 'iYmrG4yx9apBiAGrv')
      .then(
        (result) => {
          setLoading(false);
          
          toast.success('Message sent successfully', {
            onClose: () => {
              
            },
          });
        },
        (error) => {
          setLoading(false);
          toast.error('Failed to send message');
        }
      );
  };
  
  // Use ternary operator to conditionally render loading section
  return (
    <div className='main_submission_wrapper'>
      {loading ? (
        <div><SubmissionConfirmation/></div>
      ) : (
        <form ref={form} onSubmit={sendEmail} className="submission-card">
          <h1 className='heading_main_message'>DON'T BE SHY! SEND US A MESSAGE</h1>
          <label className="label_text">Name</label>
          <input className="input-field" type="text" name="user_name" />
          <label className="label_text">Email</label>
          <input className="input-field" type="email" name="user_email" />
          <label className="label_text">Message</label>
          <textarea className="input-field" name="message" />
          <input className="submit-button" type="submit" value="Send" disabled={loading} />
          <ToastContainer />
        </form>
      )}
      
    </div>
  );
}

export default SubmissionCard;
