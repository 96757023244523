import React from "react";
import Navbar from "../Navbar/Navbar";
import HeroHome from "../HeroHome/HeroHome";
import Information_Section_Main from "../Information_Section/Information_Section_Main";
import "./Home.css";
import Footer from "../Footer/Footer";

function Home() {
  return (
    <>
      
      <HeroHome />
      <Information_Section_Main />
      
    </>
  );
}

export default Home;
