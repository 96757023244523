import React from 'react'
import SubmissionCard from '../Submission/SubmissionCard'
import ContactInformation from '../ContactInformation/ContactInformation'
import './ContactUs.css'


function ContactUs() {
  return (
    <div className='center_screen'>
    <ContactInformation/>
    <SubmissionCard/>
    
   
    </div>
  )
}

export default ContactUs