import React from 'react';
import './SubmissionConfirmation.css';

const SubmissionConfirmation = () => {
  return (
    <div className="submission-confirmation">
      <h2 className="confirmation-heading">Message Sent Successfully!</h2>
      <p className="confirmation-text">We will get back to you as soon as possible.</p>
    </div>
  );
};

export default SubmissionConfirmation;
