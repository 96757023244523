import React from 'react'
import Information_Section_Card from '../Information_Section/Information_Section_Card'
import Information_Section_Main from '../Information_Section/Information_Section_Main'
import Services2 from '../Services/Services'

export default function Services() {
  return (
    <div>
      <Services2/>
    </div>
  )
}
