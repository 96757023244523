import React from "react";
import Information_Section_Card from "./Information_Section_Card";
import { informationData } from "./Information_Section_Data";
import './Information_Section.css'

function Information_Section_Main() {
  return (
    <div className="main_section">
      {informationData.map((data, index) => (
        <Information_Section_Card
          key={data.id}
          cardData={data}
          reverse={index % 2 === 1}
        />
      ))}
    </div>
  );
}

export default Information_Section_Main;
