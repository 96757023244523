import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <footer className="footer">
      <div className='footerHeading'>VULISCORE ® Vuliscore Is SAHPRA Approved ✓</div>
      <div className="container">
        <div className="row">
          <div class="footer-col">
            <h4>Physical Address</h4>
            <ul>
              <li>Virtual Premises</li>
              <li>Moving To Offices Soon</li>
            </ul>
          </div>

          <div class="footer-col">
            <h4>Contact Person</h4>
            <ul>
              <li>Sashin Chin</li>
              <li>(+27) 66 146 7140</li>
            </ul>
          </div>
          <div class="footer-col">
            <h4>Email Address</h4>
            <ul>
              <li>info@vuliscore.co.za</li>
              <li>sales@vuliscore.co.za</li>
              <li>deliveries@vuliscore.co.za</li>
              <li>orders@vuliscore.co.za</li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
