import React from "react";
import "./Information_Section.css";

function Information_Section_Card({ cardData, reverse }) {
  const cardStyle = reverse
    ? "information-section-card reverse"
    : "information-section-card";

  return (
    <div className='information-section-card'>
      <div className="information-section-image">
        <img src={cardData.image} alt={cardData.title} className="card_image_2"/>
      </div>
      <div className="information-section-text">
        <h2 className="text_heading_info">{cardData.title}</h2>
        <p className="text_small_info">{cardData.description}</p>
        <p className="text_small_info">{cardData.description2}</p>
        <p className="text_small_info">{cardData.description3}</p>
        <p className="text_small_info">{cardData.description4}</p>
      </div>
    </div>
  );
}

export default Information_Section_Card;
