import React from "react";
import { BrowserRouter as Router, Switch, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Pages/Home";
import Services from "./components/Pages/Services";
import ContactUs from "./components/Pages/ContactUs";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" exact Component={Home} />
          <Route path="/services" exact Component={Services} />
          <Route path="/contact" exact Component={ContactUs} />
        </Routes>
        <Footer/>
      </Router>
    </>
  );
}

export default App;
