import React from 'react'
import './IconPack.css'
import {FaSyringe, FaFirstAid, FaUserNurse, FaBed, FaAmbulance, FaHeartbeat, FaHeadSideMask, FaWheelchair } from 'react-icons/fa';
import {GiAcid,  GiStethoscope } from 'react-icons/gi'
import {MdOutlineEventSeat} from 'react-icons/md'
import {AiFillFolderAdd} from 'react-icons/ai'


function IconPack() {
    return (
        <div className='icon_pack_wrapper'>

            <div className='icon_set1_1'>
                <div className='icon_top'>
                <FaAmbulance/>

                </div>
                <div className='icon_title'>
                Emergency Equipment
                </div>
            </div>
            <div className='icon_set1_1'>
                <div className='icon_top'>
                <FaHeartbeat/>

                </div>
                <div className='icon_title'>
                Diagnostic Equipment
                </div>
            </div>
            <div className='icon_set1_1'>
                <div className='icon_top'>
                <FaHeadSideMask/>

                </div>
                <div className='icon_title'>
                Theatre Furniture
                </div>
            </div>
            <div className='icon_set1_1'>
                <div className='icon_top'>
                <GiStethoscope/>

                </div>
                <div className='icon_title'>
                Doctor's Rooms
                </div>
            </div>
            <div className='icon_set1_1'>
                <div className='icon_top'>
                <AiFillFolderAdd/>

                </div>
                <div className='icon_title'>
                Accessories
                </div>
            </div>
            <div className='icon_set1_1'>
                <div className='icon_top'>
                <FaBed/>

                </div>
                <div className='icon_title'>
                Medical Furniture
                </div>
            </div>
            <div className='icon_set1_1'>
                <div className='icon_top'>
                <GiAcid/>

                </div>
                <div className='icon_title'>
                Material Handling
                </div>
            </div>
            <div className='icon_set1_1'>
                <div className='icon_top'>
                <FaWheelchair/>

                </div>
                <div className='icon_title'>
                Mobility Furniture and Frail Care Furniture
                </div>
            </div>

            <div className='icon_set1_1'>
                <div className='icon_top'>
                <MdOutlineEventSeat/>

                </div>
                <div className='icon_title'>
                Seating
                </div>
            </div>

            <div className='icon_set1_1'>
                <div className='icon_top'>
                <FaUserNurse/>

                </div>
                <div className='icon_title'>
                Nursing Areas
                </div>
            </div>
            <div className='icon_set1_1'>
                <div className='icon_top'>
                <FaFirstAid/>

                </div>
                <div className='icon_title'>
                First Aid Room Day Clinic Sick Rooms
                </div>
            </div>
            <div className='icon_set1_1'>
                <div className='icon_top'>
                <FaSyringe/>

                </div>
                <div className='icon_title'>
                Consumables
                </div>
            </div>

        
            
        </div>
    )
}

export default IconPack